var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "start", align: "center", dense: "" } },
    [
      _c("v-col", { attrs: { cols: "12" } }, [
        _c("h3", [_vm._v("Solicitações de Matrícula")])
      ]),
      _c(
        "v-col",
        { attrs: { cols: "3" } },
        [
          _c("v-text-field", {
            attrs: {
              dense: "",
              outlined: "",
              "single-line": "",
              "append-icon": "search",
              label: "Filtrar solicitação"
            },
            model: {
              value: _vm.search,
              callback: function($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          })
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              search: _vm.search,
              items: _vm.items,
              "item-key": "id",
              loading: _vm.loading,
              "hide-default-footer": "",
              "loading-text": "Buscando solicitações de matrícula",
              "no-data-text": "Não foram encontradas solicitações de matrícula"
            },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function(props) {
                  return [
                    _c(
                      "tr",
                      [
                        _c("td", { staticClass: "text-xs-left" }, [
                          _vm._v(_vm._s(_vm.dmy(props.item.createdAt)))
                        ]),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "td",
                                        _vm._g(
                                          _vm._b(
                                            { staticClass: "text-xs-left" },
                                            "td",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(props.item.subject) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [_c("span", [_vm._v(_vm._s(props.item.subjectName))])]
                        ),
                        _c("td", { staticClass: "text-xs-left" }, [
                          _vm._v(_vm._s(props.item.classCode))
                        ]),
                        _c("td", { staticClass: "text-xs-left" }, [
                          _vm._v(_vm._s(props.item.professors))
                        ]),
                        _c("td", { staticClass: "text-xs-left" }, [
                          _vm._v(_vm._s(props.item.period))
                        ]),
                        _c(
                          "td",
                          { staticClass: "text-xs-left" },
                          [
                            _c(
                              "v-chip",
                              {
                                attrs: {
                                  color: _vm.getColor(props.item.status),
                                  dark: "",
                                  small: ""
                                }
                              },
                              [_vm._v(_vm._s(props.item.status))]
                            )
                          ],
                          1
                        ),
                        _c("td", { staticClass: "text-xs-left" }, [
                          _vm._v(_vm._s(_vm.dmy(props.item.deferralDate)))
                        ])
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }