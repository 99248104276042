<template>
  <v-row justify="start" align="center" dense>
    <v-col cols="12">
      <h3>Solicitações de Matrícula</h3>
    </v-col>
    <v-col cols="3">
      <v-text-field
        dense
        outlined
        single-line
        v-model="search"
        append-icon="search"
        label="Filtrar solicitação"
      ></v-text-field>
    </v-col>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :search="search"
        :items="items"
        item-key="id"
        :loading="loading"
        hide-default-footer
        loading-text="Buscando solicitações de matrícula"
        no-data-text="Não foram encontradas solicitações de matrícula"
      >
        <template v-slot:item="props">
          <tr>
            <td class="text-xs-left">{{ dmy(props.item.createdAt) }}</td>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }"
                ><td v-bind="attrs" v-on="on" class="text-xs-left">
                  {{ props.item.subject }}
                </td>
              </template>
              <span>{{ props.item.subjectName }}</span>
            </v-tooltip>
            <td class="text-xs-left">{{ props.item.classCode }}</td>
            <td class="text-xs-left">{{ props.item.professors }}</td>
            <td class="text-xs-left">{{ props.item.period }}</td>
            <td class="text-xs-left">
              <v-chip :color="getColor(props.item.status)" dark small>{{
                props.item.status
              }}</v-chip>
            </td>
            <td class="text-xs-left">{{ dmy(props.item.deferralDate) }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { formatDate } from "@/utils/formatDate";

export default {
  name: "EnrollmentRequest",
  props: ["username"],
  data() {
    return {
      search: "",
      items: [],
      loading: false,
      headers: [
        {
          sortable: true,
          text: "Solicitado em",
          value: "createdAt",
          align: "left",
        },
        {
          sortable: true,
          text: "Disciplina",
          value: "subject",
          align: "left",
        },
        {
          sortable: false,
          text: "Turma",
          value: "classCode",
          align: "left",
        },
        {
          sortable: true,
          text: "Professor",
          value: "professors",
          align: "left",
        },
        {
          sortable: false,
          text: "Período",
          value: "period",
          align: "left",
        },
        {
          sortable: true,
          text: "Status",
          value: "status",
          align: "left",
        },
        {
          sortable: true,
          text: "Deferido em",
          value: "deferralDate",
          align: "left",
        },
      ],
    };
  },

  created() {
    let code = this.$route.params.username;
    this.getCurrentCandidate(code);
  },

  computed: {
    ...mapState("candidateEnrollment", [
      "selectedClassesEnrollment",
      "candidate",
    ]),
  },

  methods: {
    ...mapActions("candidateEnrollment", [
      "ActionGetEnrollmentRequest",
      "ActionGetCandidate",
    ]),

    async getAsyncActionGetEnrollmentRequest(id) {
      this.loading = true;
      try {
        await this.ActionGetEnrollmentRequest(id);
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },

    async getCurrentCandidate(code) {
      try {
        await this.ActionGetCandidate(code);
      } catch (e) {
        console.error(e);
      }
    },

    dmy(data) {
      return formatDate.dmy(data);
    },

    getProfessors(professors) {
      let p = "";
      if (professors !== null) {
        professors.map((e) => {
          p += e.name + "\n";
        });
      }
      return p;
    },

    getColor(status) {
      if (status === "Solicitado") return "blue";
      if (status === "Deferido") return "green";
      return "red";
    },
  },

  watch: {
    username() {
      this.getCurrentCandidate();
    },

    selectedClassesEnrollment(newValue) {
      this.items = [];
      if (newValue) {
        newValue.map((e) => {
          this.items.push({
            id: e.id,
            createdAt: e.createdAt,
            subject: e.classTO.subject.code,
            subjectName: e.classTO.subject.name,
            classCode: e.classTO.code,
            schedule: e.classTO.classSchedules,
            classroom: e.classTO.classSchedules,
            professors: this.getProfessors(e.classTO.professors),
            period: e.classTO.semester + "/" + e.classTO.year,
            status:
              e.deferred === null
                ? "Solicitado"
                : e.deferred === true
                ? "Deferido"
                : "Indeferido",
            deferralDate: e.deferralDate,
          });
        });
      }
    },
    candidate(newValue) {
      this.getAsyncActionGetEnrollmentRequest(newValue.id);
    },
  },
};
</script>

<style scoped>
</style>
